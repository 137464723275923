@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @import url('https://cdn.jsdelivr.net/gh/orioncactus/pretendard/dist/web/static/pretendard-dynamic-subset.css');
  @import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

  html {
    font-family: 'Pretendard', 'Poppins', ui-sans-serif, system-ui, -apple-system,
      BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif,
      'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
    color: #1f1f1f;
  }
  ::-webkit-scrollbar {
    width: 7px;
    height: 7px;
  }
  .no-scroll::-webkit-scrollbar {
    width: 0px;
    height: 0px;
  }
  ::-webkit-scrollbar-track {
    background-color: transparent;
    border-radius: 100px;
  }
  ::-webkit-scrollbar-thumb {
    background-image: linear-gradient(180deg, #a557d5, #7e3ea5);
    border-radius: 100px;
  }

  /* 크롬 input 자동완성 백그라운드 색 제거 */
  input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 1000px white inset;
    box-shadow: 0 0 0 1000px white inset;
  }

  /*익스플로러 기본 화살표 없애기*/
  select::-ms-expand {
    display: none;
  }
  /*화살표 기본 css 없애기*/
  select {
    -o-appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }

  .wrapper {
    @apply pt-[70px];
  }
}

@layer components {
}
